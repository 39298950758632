import Text from '@atoms/Text';
import ReferralAssetCard from '@components/Referral/ReferralAssetCard';
import texts from '@components/Homepage/en.json';

const AssetClasses = () => {
    return (
        <div className="md:w-full max-w-5xl md:mx-auto md:px-3 xl:px-0 mx-4">
            <Text
                content={
                    <>
                        {texts.AssetClassesHeadingPrefix}{' '}
                        <br className="xl:block hidden" />
                        <span className="h5-semibold md:h4-semibold text-primary-500 lowercase">
                            {texts?.AssetClassesHeadingSuffix}
                        </span>
                    </>
                }
                htmlTag='h2'
                className="h5-regular md:h4-regular text-gray-900 text-center mb-6 md:mx-10 xl:mx-0 md:block hidden"
            />
            <div className="md:hidden text-center flex flex-col gap-2 mb-4">
                <Text
                    content={texts?.AssetClassesHeadingSuffix}
                    className="text-primary-500 h5-semibold"
                    htmlTag='h2'
                />
                <Text
                    content={texts?.AssetClassesHeadingPrefixMobile}
                    className="text-primary-500 p5-regular mx-3"
                />
            </div>

            <ul className="list-inline grid grid-cols-4 asset-classes-list animated-items-list flex-wrap xl:m-auto xl:px-0">
                <ReferralAssetCard isLinkDisable={true} />
            </ul>
        </div>
    );
};

export default AssetClasses;
