import React from 'react';
import Text from '@atoms/Text';
import { assetClasses } from '@helpers/homepageData';
import LazyImage from '@atoms/LazyImage';
import { useRouter } from 'next/router';
import texts from '@components/Referral/en.json';
function ReferralAssetCard({ isLinkDisable = false }) {
    const router = useRouter();

    return assetClasses.map((data, index) => {
        return (
            <li
                key={index}
                className={`${
                    index % 2 === 0 ? 'mr-[4px]' : 'ml-[4px]'
                } col-span-full md:col-span-2 my-2 md:m-3 xl:m-3 asset-class ${
                    !isLinkDisable ? 'cursor-pointer' : 'rounded-3xl'
                } 
                md:order-none 
                ${index % 3 === 0 ? 'bg-primary-10' : 'bg-secondary-20'}
                ${index < 2 ? 'order-1' : ''}
                ${index === 3 ? 'order-2' : ''}
                ${index === 2 ? 'order-3' : ''}
                `}
                onClick={
                    isLinkDisable ? () => {} : () => router.push(data?.link)
                }
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
                data-aos-delay="300"
            >
                <div className="rounded-3xl flex justify-between p-6">
                    <header className="asset-class-item--header relative rounded-t-xl gap-3 flex flex-col items-start justify-between ">
                        <Text
                            content={data.title}
                            // isInnerHtml={true}
                            className={`p4-semibold md:p3-semibold ${
                                index % 3 === 0
                                    ? 'text-primary-500'
                                    : 'text-secondary-700'
                            } text-left`}
                            htmlTag='h3'
                        />
                        <ul className="flex justify-between list-inline irr-tenure gap-6 md:gap-10">
                            <li
                                className={`p4-medium text-left text-basicBlack`}
                            >
                                <Text
                                    content={texts.IRR}
                                    htmlTag="span"
                                    className={`block mb-[2px] p5-medium text-left font-normal not-italic ${
                                        index % 3 === 0
                                            ? 'text-primary-300'
                                            : 'text-secondary-700'
                                    }`}
                                />
                                {data.irr}
                            </li>
                            <li className="p4-medium text-left font-normal text-basicBlack">
                                <Text
                                    content={texts.Tenure}
                                    htmlTag="span"
                                    className={`block mb-[2px] p5-medium text-left font-normal not-italic ${
                                        index % 3 === 0
                                            ? 'text-primary-300'
                                            : 'text-secondary-700'
                                    }`}
                                />
                                {data.tenure}
                            </li>
                        </ul>
                    </header>
                    <div className="">
                        <LazyImage
                            src={data.image}
                            alt={data.title}
                            className={`relative w-[40px] h-[40px] md:w-[56px] md:h-[56px] xl:w-[72px] xl:h-[72px] inline-block`}
                        />
                    </div>
                </div>
            </li>
        );
    });
}

export default ReferralAssetCard;
